import {createRouter, createWebHistory} from 'vue-router'
import Index from '../views/Index.vue'


// const router = createRouter({
//   history: createWebHashHistory(),
//   routes: [{
//     name: 'Home',
//     path: '/',
//     component: () => import( /* webpackChunkName: "home" */ '../views/home/index.vue'),
//   },{
//     path: '/:pathMatch(.*)',
//     redirect: {
//       name: 'Home'
//     }
//   }]
// })


const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import( /* webpackChunkName: "home" */ '../views/Index.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
