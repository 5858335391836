<template>
  <router-view/>
</template>

<style lang="less">
//.el-popper.is-dark{
//  background: #3971eb!important;
//  backdrop-filter: blur(8px);
//  border-radius: 10px!important;
//  padding: 0;
//  border:none!important;
//}
//.el-popper__arrow::before{
//  background: #3971eb!important;
//  backdrop-filter: blur(8px);
//}
//.el-popper.is-dark .el-popper__arrow::before{
//  border: none!important;
//}

</style>
