<template>
  <div class="content">
    <div class="scroll-box">
      <div class="top-container">
        <div class="top-header flex jc-sb ai-c">
          <div class="pc-pl"><img src="@/assets/image/top-logo-pro.png" class="top-logo cp" alt=""></div>
          <div class="flex jc-fs ai-c">
            <a target="_blank" class="a-btn">홈페이지</a>
            <a target="_blank" href="http://recharge.momoindo.live" class="a-btn">충전하다</a>
            <a href="https://momoindo.s3.ap-southeast-1.amazonaws.com/Momoindo_1.0.0.apk" class="a-btn">앱 다운로드</a>
            <a target="_blank" @click="toBottom" class="a-btn">문의하기</a>
          </div>
          <img @click="visible = true" src="@/assets/image/top-select.svg" class="top-select cp" alt="">

        </div>
        <div class="m-t-content">
          <div class="flex jc-c ai-c m-img-box"><img src="@/assets/image/main-img.png"
                                                     class=""
                                                     alt=""></div>
          <div class="fs-36 lh-52 fw-900 ta-c"
               style="margin-bottom: 18px;font-style: normal;font-weight: 900;">새로운 배움의 시작
          </div>
          <div style="padding: 0 37px" class=" fs-14 lh-22 ta-c">자격증부터 외국어, 취미 클래스까지! 국내 최대 온라인 클래스 플랫폼!
          </div>
          <div class="m-btn-box flex">
            <div class="top-btn-item">
              <a class="common-btn demo-btn cp" href="https://momoindo.s3.ap-southeast-1.amazonaws.com/Momoindo_1.0.0.apk">Android</a>
            </div>

            <el-tooltip
                class="box-item" trigger="click"
                effect="dark"
                content="계속 지켜봐 주시기 바랍니다"
                placement="top"
            >
              <div class="top-btn-item">
                <a target="_blank" class="common-btn cp">iOS</a>
              </div>
            </el-tooltip>


          </div>
        </div>
        <div class="top-content flex">
          <div class="top-text">
            <div class="">새로운 배움의 시작</div>
            <div>자격증부터 외국어, 취미 클래스까지! 국내 최대 온라인 클래스 플랫폼!</div>
            <div class="top-btn-container flex">
              <div class="top-btn-item">
                <a class="common-btn demo-btn cp" href="https://momoindo.s3.ap-southeast-1.amazonaws.com/Momoindo_1.0.0.apk">Android</a>
              </div>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="계속 지켜봐 주시기 바랍니다"
                  placement="top"
              >
                <div class="top-btn-item">
                  <a target="_blank"
                     class="common-btn cp">iOS</a>
                </div>
              </el-tooltip>
            </div>
          </div>
          <!--          <img src="@/assets/image/main-img.png" class="top---img" alt="">-->

        </div>
        <img src="@/assets/image/main-img.png" class="top-pos-img" alt="">
      </div>
      <div class="advantage-container ">
        <div class="advantage-item  flex fd-c jc-fs ai-c">
          <img src="@/assets/image/top-merchant.png" class="img-item" alt="">

          <div class="advantage-title">온라인 비디오</div>
          <div class="advantage-text">더욱 직관적인 교수법과 몰입형</div>
          <div class="advantage-text">수업 분위기</div>
        </div>
        <div class="advantage-item flex fd-c jc-fs ai-c">
          <img src="@/assets/image/top-proxy.png" class="img-item" alt="">
          <div class="advantage-title">선임강사</div>
          <div class="advantage-text">한국의 유명 강사가 전문적인</div>
          <div class="advantage-text">수업을 진행합니다.</div>
        </div>
        <div class="advantage-item flex fd-c jc-fs ai-c">
          <img src="@/assets/image/top-shouyin.png" class="img-item" alt="">
          <div class="advantage-title">종합 카테고리</div>
          <div class="advantage-text">외국어, 비즈니스, 문화, 예술</div>
          <div class="advantage-text">강좌가 개설되어 있습니다.</div>
        </div>
        <div class="advantage-item flex fd-c jc-fs ai-c">
          <img src="@/assets/image/top-set.png" class="img-item" alt="">
          <div class="advantage-title">빠른 개선</div>
          <div class="advantage-text">학생이든 직장인이든 빠르게</div>
          <div class="advantage-text">실력이 향상될 수 있습니다.</div>
        </div>
      </div>
      <div class="feature-title">모모인도를 선택하는 이유</div>
      <div class="demo-container demo-1">
        <div>
          <div class="demo-title">편리하고 빠릅니다</div>
          <div class="demo-desc w-430 ta-l">당신이 보는 것은 온라인 과정을 통해 매일 조금씩 배우는 것입니다.</div>
          <div class="demo-desc w-430 ta-l">빠른 진전을 이루는 데는 약간의 시간이 걸립니다.</div>
        </div>
        <img v-if="lang === 'cn'" src="@/assets/image/mid-shouyin.png" class="demo-img" alt="">
        <img v-else src="@/assets/image/mid-shouyin.png" class="demo-img" alt="">
        <div>
          <div class="demo-title">편리하고 빠릅니다</div>
          <div class="demo-desc w-430 ta-l">당신이 보는 것은 온라인 과정을 통해 매일 조금씩 배우는 것입니다.</div>
          <div class="demo-desc w-430 ta-l">빠른 진전을 이루는 데는 약간의 시간이 걸립니다.</div>
        </div>
      </div>

      <div class="demo-container demo-2 mt-158">
        <div>
          <div class="demo-title ta-l">권위 있는 교육 결과</div>
          <div class="demo-desc  w-460 ta-l">세계 유일의 탐구 기반 온라인 교육 시스템은 권위 있는 연구 결과와 실제 적용을 기반으로 합니다.</div>
          <div class="demo-desc  w-460 ta-l">중국어 학습에 대한 흥미를 불러일으키세요. 체험 수업은 무료입니다.</div>
        </div>
        <img src="@/assets/image/mid-set.png" class="pay-img mid-h5" alt="">
      </div>

      <div class="demo-container demo-3">
        <div>
          <div class="demo-title">자신을 향상시키다</div>
          <div class="demo-desc w-430 ta-l">전문적인 기술을 향상시키거나, 지식을 넓히고 싶거나, 내면의 평화와 행복을 찾고 싶다면 이 과정이 여러분에게 영감을 주고 도움을 줄 수
            있습니다.
          </div>
          <div class="demo-desc w-430 ta-l"> 자신에게 맞는 코스를 선택하고, 학습 계획을 세우고, 꾸준히 실천하는 것을 잊지 마세요.</div>
        </div>
        <img v-if="lang === 'cn'" src="@/assets/image/mid-usdt.png" class="demo-img" alt="">
        <img v-else src="@/assets/image/mid-usdt.png" class="demo-img" alt="">
        <div>
          <div class="demo-title">자신을 향상시키다</div>
          <div class="demo-desc w-430 ta-l">전문적인 기술을 향상시키거나, 지식을 넓히고 싶거나, 내면의 평화와 행복을 찾고 싶다면 이 과정이 여러분에게 영감을 주고 도움을 줄 수
            있습니다.
          </div>
          <div class="demo-desc w-430 ta-l">자신에게 맞는 코스를 선택하고, 학습 계획을 세우고, 꾸준히 실천하는 것을 잊지 마세요.</div>
        </div>
      </div>

      <div class="bg-img">
        <div class="platform-container adaptation fd-c flex jc-c ai-c ">
          <div class="ta-c">모모인도 모바일 앱</div>
          <div class="ta-c">모모인도 모바일 앱과 함께 사용하면 더욱 간편해집니다!</div>
          <img v-if="lang === 'cn'" src="@/assets/image/mid-bg-img.png" class="platform-img" alt="">
          <img v-else src="@/assets/image/mid-bg-img.png" class="platform-img" alt="">
        </div>
      </div>


      <div class="bg-f5">
        <div class="partners-container adaptation">
          <div class="ta-c">제품 특징</div>
          <div class="partners-box ">
            <div class="partner-item">
              <img src="@/assets/image/1.png" class="partner-img" alt="">
              <div>풍부한 코스 리소스</div>
              <div></div>
              <div></div>
            </div>
            <div class="partner-item">
              <img src="@/assets/image/2.png" class="partner-img" alt="">
              <div>신뢰할 수 있는 자격</div>
              <div></div>
              <div></div>
            </div>
            <div class="partner-item">
              <img src="@/assets/image/3.png" class="partner-img" alt="">
              <div>고품질 코스</div>
              <div></div>
              <div></div>
            </div>
            <div class="partner-item">
              <img src="@/assets/image/4.png" class="partner-img" alt="">
              <div>모바일이 더 편리해요</div>
              <div></div>
              <div></div>
            </div>
            <div class="partner-item">
              <img src="@/assets/image/5.png" class="partner-img" alt="">
              <div>강좌는 영원히 유효합니다.</div>
              <div></div>
              <div></div>
            </div>
            <div class="partner-item">
              <img src="@/assets/image/6.png" class="partner-img" alt="">
              <div>전문과정 설명</div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="evaluate-box adaptation">
          <div class="evaluate-title">전 세계 사용자의 리뷰</div>
          <div class="e-item-box">
            <div class="evaluate-item">
              <div>
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
              </div>
              <div class="e-item-text">
                놀랍게도 저는 많은 것을 배웠고 다른 비즈니스 과정도 계속 공부할 것입니다. 고마워요 모모인도
              </div>
              <div class="e-b-box">
                <img src="../assets/image/avatar1.png" alt="">
                <div class="">
                  <div class="e-b-name">임기범</div>
                  <div class="e-b-job">대학생들</div>
                </div>
              </div>
            </div>
            <div class="evaluate-item">
              <div>
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
              </div>
              <div class="e-item-text">
                모모인도에서 비즈니스 강좌를 수강했는데 많은 도움이 되었습니다. 자기 개선이 이루어졌습니다.
              </div>
              <div class="e-b-box">
                <img src="../assets/image/avatar2.png" alt="">
                <div class="">
                  <div class="e-b-name">최수영</div>
                  <div class="e-b-job">사무원</div>
                </div>
              </div>
            </div>
            <div class="evaluate-item">
              <div>
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star.svg" alt="">
                <img src="../assets/image/star2.svg" alt="">
              </div>
              <div class="e-item-text">
                문화와 예술 과정이 훌륭하고, 나는 모든 수업을 정시에 출석할 것입니다. 더 많은 것을 배우기를 바랍니다!
              </div>
              <div class="e-b-box">
                <img src="../assets/image/avatar3.png" alt="">
                <div class="">
                  <div class="e-b-name">차상우</div>
                  <div class="e-b-job">프리랜서</div>
                </div>
              </div>
            </div>
          </div>
          <div class="evaluate-title evaluate-margin">생태학적 파트너</div>
          <img src="../assets/image/logo2.png" class="partners-img pc-visible" alt="">
          <img src="../assets/image/logo2.png" class="partners-img m-visible" alt="">
          <div style="height: 1px;"></div>
        </div>
      </div>

      <div class="bg-f7f9fc">
        <div class="copy-right adaptation">
          <div>연락처 정보</div>
          <ul>
            <li class="bot-li-item" @click="openModal(true);current = 2">이용약관 (Terms of Use)</li>
            <li class="bot-li-item" @click="openModal(true);current = 1"> 개인 정보 정책 (Privacy Policy)</li>
            <li class="bot-li-item" @click="openModal(true);current = 3">환불 정책 (Refund Policy)</li>
<!--            <li class="bot-li-item"><a>hello@momoindo.live</a></li>-->
          </ul>
          <div class="bot-limit">
            NewFriend International (HK) Limited
          </div>
          <div class="bot-email">
            <a  href="mailto:service@momoindo.live?subject=&body=" target="_blank">이메일: service@momoindo.live (09:00~18:00)</a>
          </div>
          <div class="bot-address">
            주소: Room 1512, 15th Floor, Lucky Center, 165-171 Wan Chai Road, Hong Kong
          </div>

        </div>
      </div>
      <div class="bg-f2f4f8" style="background: #f2f4f8">
        <div class="adaptation copy-r">© {{ new Date().getFullYear() }} Momoindo</div>
      </div>
    </div>
    <el-drawer v-model="visible" :append-to-body="true" :show-close="false" class="el-drawer-reset" title=""
               direction="btt"
               :with-header="false">
      <div class="px-3">
        <a target="_blank"
           class="border-b py-3 ta-c cp">홈페이지</a>
        <a target="_blank" href="http://recharge.momoindo.live" class="border-b py-3 ta-c cp">충전하다</a>
        <a class="border-b py-3 ta-c cp" href="https://momoindo.s3.ap-southeast-1.amazonaws.com/Momoindo_1.0.0.apk">앱 다운로드</a>
        <a target="_blank" @click="toBottomH5" class="py-3 ta-c cp">문의하기</a>
      </div>
    </el-drawer>
    <div v-show="dialogVisible" class="auto-modal">
      <div class="modal">
        <img src="../assets/image/no.svg" @click="dialogVisible = false" class="close-img" alt=""/>
        <div class="modal-c">
          <div class="modal-t">
            <div>{{ current === 1 ? ' Privacy Policy' : current === 2 ? 'Terms of Use' : 'Refund Policy ' }}</div>
            <div class="modal-t-2">{{ current === 1 ? '개인 정보 정책' : current === 2 ? '이용약관' : '환불 정책' }}</div>
          </div>
          <pre v-if="current === 1" class="modal-content">

            Momoindo (hereinafter referred to as the "Company") complies with the Personal Information Protection Act, the Communications Secrets Protection Act, the Telecommunications Business Act, and the Information and Communications Network Utilization Promotion and Information Protection Act. The "Company" hopes to inform users through this personal information processing policy of the purpose and method of use of the personal information provided by users, as well as the measures taken to protect personal information.

            This Personal Information Processing Policy may change from time to time due to changes in government laws and guidelines or changes in the Company's internal policies. Any revised information will be released to the Company at least 7 days before implementation. Personal information processing policy for easy identification. We will notify you via the website/mobile site, app or email provided by "."

            (1) The “Company’s” personal information processing policy includes the following.

            1. The purpose of collecting and using personal information, items of personal information collected and collection methods;
            2. Provide personal information to third parties;
            3. Entrust the processing of personal information;
            4. Processing and storage period of personal information;
            5. Personal information destruction procedures and methods;
            6. Rights, obligations and methods of exercise of users and legal representatives;
            7. Matters regarding installation/operation and rejection of automatic collection of personal information;
            8. Technical and managerial measures to protect personal information;
            9. Name, contact information, and department of the person in charge of personal information management;
            10. Notification obligations.

            (2) Purpose of collecting and using personal information, items of personal information collected and collection methods

            1. Personal information refers to information that can identify a living individual (even if the information itself cannot identify a specific individual, it also includes information that can be easily combined with other information to identify the individual).
            2. The "Company" collects personal information with the user's consent within the minimum scope of providing services, and all collected personal information is only used within the scope of the notified purpose.

            (2) Personal information collection items and purposes

            1. Common to members
            ① Collection content: name, ID (email or mobile phone number), password, date of birth;
            ② Purpose of use: member identity verification, preventing fake registration, confirming intention to join, handling complaints and consultations, notification information, etc.;
            ③ Collection content: (automatically collected information) service usage records, access logs, access IP information, cookies, device information, suspension records, termination records, payment records;
            ④ Purpose of use: Prevent illegal/unauthorized use, comply with the validity period of personal information, access management, shopping cart, identify recently viewed content and products, provide customized services for each user, confirm usage statistics.

            2. Membership registration for children under 14 years old
            ① Content collected: name of legal representative, mobile phone number, certification results, and consent status;
            ② Purpose of use: consent of legal representative and identity verification.

            3. Easily register through SNS account
            ① META: SNS account name, email
            ② Apple: Name, mobile phone number, email
            ③ Purpose of use: Register using your SNS account

            4. When ordering/purchasing
            ① Collected content: encrypted payment information (for subscription payments and simple payments), payment records, bank account information (for virtual account payment refunds);
            ② Purpose of use: content and product payment/refund services;
            ③ Content collected: recipient’s name, shipping address, shipping address contact information;
            ④ Purpose of use: Confirm the delivery address and contact information for product delivery (exchange/return).

            5. Others
            ① Name (real name), mobile phone number, country, postal code, address;
            ② Non-commercial general master content distribution;
            ③ Resident ID card number, copy of ID card, bank name, account number, account holder, and copy of passbook;
            ④ Sell the content of non-commercial high-quality masters and obtain sales proceeds (settlement);
            ⑤ Name (real name), mobile phone number, business name (company name), business registration number, representative name, main phone number, country, postal code, address;
            ⑥ Content distributed by general owners of registered individual industrial and commercial households and corporate legal persons;
            ⑦ Mail order business report number, bank name, account number, account holder, copy of passbook, business registration certificate, and mail order business report;
            ⑧ Registered senior masters of individual industrial and commercial households and legal entities sell content/products and collect sales revenue (settlement);
            ⑨ Collect items until you withdraw from membership (however, if relevant laws and regulations provide for it, until the statutory mandatory retention period).


            Instructor means an individual or corporate member who registers on the Service and distributes or sells content or products, and has the same meaning below.

            (3) When the "Company" agrees to additional collection of personal information, in the process of applying for additional services or activities, the "Company" may collect information about service users (name, mobile phone number, survey answers, etc.), and may notify that it can be saved a period of time. In this case, the user clicks the consent information collection button or fills in and saves the additional personal information collected by the "Company", which is deemed to agree to the collection of personal information.

            (4) When the "Company" collects users' personal information, it must obtain the user's consent and collect information that may infringe on the user's basic human rights, including race, place of origin, residence, ideology, political affiliation, and criminal record, unless the user consents or in accordance with the law. stipulations, otherwise such information would not be collected.

            (5) "Company" may collect personal information in the following ways.
            ① Home page (member registration, bulletin board, etc.), mobile application, written, fax, telephone, email, customer service consultation, event application (online/offline)
            ② Automatically collect through generated information collection tools
            "Company" affiliated with external companies Personal information may be provided by companies or organizations (in this case, the affiliated company provides personal information to the "Company" after obtaining the user's consent to provide personal information in accordance with the Personal Information Protection Act)

            2. Providing personal information to third parties
            (1) In principle, the "Company" does not provide users' personal information to outside parties. The following exceptions apply.

            ① When the user agrees to provide the user’s personal information to a specific third party for a specific purpose
            A. Mail order business registration store
            Purpose of provision: delivery of ordered goods, exchanges, customer consultation
            Items provided: Recipient information (name, mobile phone number, shipping address)
            Storage and use period: until the purpose of using personal information is achieved

            B. National Lifelong Education Promotion Agency
            Purpose provided: Administrative reporting such as Lifetime Voucher learner registration and attendance reports
            What's offered: products purchased, course history
            Storage and usage period: Please refer to the privacy policy of the National Institute for the Promotion of Lifelong Education Lifelong Education Voucher website.

            ② When courts, investigative agencies, etc. make requests for investigation purposes in accordance with legal provisions or in accordance with legal procedures and methods
            ③ Statistical compilation, scientific research, public record keeping, market research, etc. to identify specific individuals for investigation purposes. If the pseudonym information you provided has been processed into an unusable format

            Users may not consent to the provision of personal information to third parties and may withdraw their consent to the provision of personal information to third parties at any time. However, use of certain related services provided by third parties may be restricted.

            3. Entrustment of personal information processing
            (1) In order to provide smooth services, the "Company" entrusts the processing of necessary personal information to the following external companies to a minimum extent. (Retention and use period: until membership withdrawal or termination of the entrustment contract)

            (2) When signing an entrustment contract, the "Company"'s liability-related matters in accordance with the Personal Information Protection Act, technical/management protection measures, restrictions on re-entrustment, supervision of management/recipients, and compensation for damages are all clearly stipulated in the contract and other documents. , and supervise whether trustees handle personal information securely.

            (3) Users can refuse the transfer of personal information overseas through the company’s person in charge of personal information protection or the competent department. If a user refuses to transfer personal information overseas, the company will exclude the user's personal information from being transferred overseas. However, in this case, use of the Company's services (which necessarily involves the overseas transfer of personal information) may be restricted.

            (4) If the details of the entrusted work or the trustee are changed, the "Company" will promptly disclose it through this personal information processing policy.

            4. Processing and storage period of personal information
            (1) The "Company" can save the information provided by the user when registering as a member from the time of registering as a member until the date of receipt of the withdrawal application. In principle, after the member's withdrawal process is completed, the member's personal information will be completely deleted using the "Company's" technical methods, and the printed statement will be shredded or burned so that it cannot be used for any purpose. However, if separate consent is obtained from the individual user for the storage period of personal information, or if there is an obligation to store information for a certain period by law, personal information will be stored securely during that period.

            (2) The law requires the retention of information within a certain period of time as follows, therefore, member information will be stored separately in a separate database that is isolated from the outside world.

            ① Basis for preservation: Electronic Commerce and Other Consumer Protection Act (Electronic Commerce Act), Communications Secrets Protection Act, Value-Added Tax Act
            ② Shelf life:
            Record of contract or subscription cancellation (E-Commerce Law): 5 years
            Records of payment and supply of goods and services (E-commerce Act): 5 years
            Consumer complaint or dispute resolution records (E-commerce Law): 3 years
            Display and advertising records (e-commerce law): 6 months
            Access records (Communications Privacy Protection Act): 3 months
            VAT tax base and tax amount declaration data, etc. (VAT Law): 5 years

            (3) Based on the validity period of personal information, the "Company" will notify users in advance of the membership registration information of users who have no service usage records for more than one year and keep it separately. (Unless there are special provisions in relevant laws and regulations, individually stored personal information will not be used or provided) However, as stated in paragraph (2) of this article, the storage period has obtained the individual user's separate consent. In this case, we will The corresponding processing will be carried out. If it is a master, the personal information will be retained until the date of receipt of the member's withdrawal application.

            5. Procedures and methods for destroying personal information
            (1) In principle, users’ personal information should be destroyed immediately when the personal information is no longer needed, such as when the personal information storage period exceeds or the purpose of processing is achieved. However, if personal information needs to be saved based on other laws and regulations specified in Section 4. Processing and storage period of personal information, it will be transferred to a separate database and stored securely for a certain period in accordance with internal regulations. regulations and relevant laws and then destroyed immediately.

            (2) The method of destroying personal information is as follows.
            ① Adopt technical methods to securely delete information in the form of electronic files so that they cannot be recovered or copied.
            ② Information in printed form is destroyed by crushing or incineration.

            6. Rights and obligations of legal representatives and users and how to exercise them
            (1) Users may request to review, correct, withdraw consent, delete (withdraw), stop processing, object, etc. regarding the following matters at any time.

            ① User personal information held by “Company”
            ② Details of user personal information used by the "Company" or provided to third parties
            ③ Details of consent to "Company" collecting, using, and providing personal information, etc. "Company"

            Information query and modification: manage my information after logging in - basic information, address management
            Cancel membership: Manage my information after logging in-Basic information-Cancel membership
            Withdraw consent (to receive newsletters): Log in and manage your data – Turn off receipt of newsletters
            ④ The "Company" has an independent customer center to handle related inquiries and inquiries. If you contact the person in charge of personal information protection by email or phone, we will take immediate action.

            (2) Users may request to suspend the processing of their personal information at any time.

            (3) Users may withdraw their consent to the collection, use and provision of personal information at any time during membership registration (exit membership). However, the "Company" may retain minimum information in accordance with relevant laws and regulations.

            (4) For children under the age of 14, the legal representative has the right to view, modify, delete the child's personal information, suspend processing, and withdraw consent for collection and use.

            (5) If a user requests correction of errors in personal information, we will not use or provide the personal information until the correction is completed. In addition, if incorrect personal information has been provided to a third party, we will immediately notify the third party of the results of the correction so that the correction can be made.

            7. Matters related to the setting, operation and rejection of automatic personal information collection devices
            (1) "Company" uses "cookies" to frequently store and retrieve user information. A cookie is a string of information that a web server sends to a user's web browser, stores it, and sends it back to the server when other requests are made. "Company" uses "cookies" for the following purposes.
            ① Purpose of use:

            Keep users connected to their devices
            Save information set by the user on the device
            By analyzing the user's access frequency and access time, identifying the user's tastes and interests, tracking traces, determining the degree of participation in various events and the number of visits, and providing personalized services.
            ② Users can choose to install "cookies" and change the settings in their web browser to allow all "cookies", confirm each time a "cookie" is saved, or refuse to save all "cookies". Can. However, if the user refuses to install cookies, it may cause inconvenience in using the service or difficulty in providing the service.

            ③ How to specify whether to allow the installation of cookies is as follows.
            1. Chrome (example): At the top of your web browser, go to [Settings] > [Privacy & Security] > Set cookie blocking level

            8. Technical and managerial measures to protect personal information
            (1) The user's personal information is thoroughly protected by a password, and only the user knows the password that matches the email address provided by the user. Therefore, checking and changing personal information can only be done by persons who know the password.

            (2) Users are asked to enter personal information accurately and timely to prevent accidents. Any accidents that occur due to inaccurate entered information are the user's sole responsibility. If you enter false information, such as stealing other people's information, you may lose your membership.

            (3) Users shall not disclose their membership password to anyone. We also recommend that you avoid using the Service in public places or on other people's computers and terminals.

            (4) The "Company" assumes no responsibility for any situation that occurs due to the user's personal errors or basic Internet risks. Users are obliged to properly manage their IDs and passwords and assume the responsibility for protecting their personal information.

            (5) The "Company's" technical and managerial measures to protect personal information are as follows.
            ① Device that encrypts identity-verifiable information such as passwords in collected personal information
            ② Install, update, and check measures for anti-virus software to prevent computer viruses from invading personal information
            ③ Setting, managing and invading access rights to personal information systems using access control devices such as blocking systems
            ④ Designation of personal information processors, establishment of authority and training, and security management of personal information
        </pre>

          <pre v-if="current === 2" class="modal-content">


            Article 1 [Purpose]

            These Terms of Use (hereinafter referred to as the "Terms") stipulate the rights, obligations and responsibilities between the "Company" and the "User" and other necessary matters related to the use of the "Services" provided by Momoindo. (hereinafter referred to as the "Company"). The purpose is to do just that.

            Article 2 (Definition of terms)

            (1) The terms used in these Terms and Conditions are defined below.

            ① "Service" refers to the course services provided by the "Company" https://www.momoindo.live
            ② "Paid Services" refers to all services including "Contents" and "Products" provided by the "Company" for a fee through the "Services".
            ③ "Content" refers to knowledge-related videos published within the "Service" and various related posts, files, links, etc. Whether the Content is knowledge-related is solely determined by the Company.
            ④ "Products" refers to various textbooks, goods, and other services and services sold within the scope of the "Services".
            ⑤ "Package" refers to a product that combines various "contents" or "products" and sells them at a discounted price.
            ⑥ "Member" refers to a person who can continue to use the information and "services" provided by the "Company" by accessing the "Services" of the "Company" and agreeing to these "Terms", and signing a service agreement with the "Company". "Companies" do this.
            ⑦ "User" refers to non-members and "members" who access the "Services" of the "Company" and use the "Services" provided by the "Company" in accordance with these "Terms".
            ⑧ "Password" refers to the letters and numbers set by the "Member" to confirm that the "Member" matches the name and "email address" provided by the "Member" and to protect the privacy of the "Member". The combination.
            ⑨ "E-mail" refers to mail sent via the Internet or mail using electronic media (e-mail).
            ⑩ "Category" refers to the space of "content", "products" and their bundles within the "service".
            ⑪ "Master" refers to an individual or corporate "member" who registers "content" or "products" in the "service" and distributes or sells them.
            ⑫ "Category" refers to the "category" classified according to the standards established by the "Company" considering the nature of the "content".
            ⑬ "Cash" refers to the virtual currency used to use paid "content" in the "service", and the exchange ratio with currency (Korean won) is 1:1. However, "cash" does not refer to actual currency (Korean won) and is treated the same as the "content" products specified in this item ②.
            ⑭ "Recharge" means that when the "Member" pays cash to the "Company" in accordance with the payment method designated by the "Company", the "Company" pays the "Member" the corresponding amount of "cash".
            ⑮ "Discount Code" means a code designed to allow you to purchase "Content" or "Products" at a discounted price.
            ⑯ "Course Suspension" refers to the function of temporarily suspending the course period of the "Course" purchased by the "Member" and retaining the remaining course availability period. In addition, the number and duration of "suspension" shall be subject to the policies stipulated by the "Company".

            (2) In addition to the definitions applicable to the preceding paragraph, the definitions of other terms shall be subject to industry practices and relevant laws.

            Article 3 (Effectiveness and Changes)

            (1) These "Terms" will take effect when posted and published on the "Services" provided by the "Company".
            (2) If there are reasonable reasons, these "Terms and Conditions" may be modified to the extent that it does not violate the "Terms and Conditions Regulation Act" and the "Information and Communications Promotion Act" and other relevant laws. Network utilization and information protection, etc." In addition, the revised "Terms" will take effect as soon as they are published online, and revisions to important provisions such as the rights or obligations of "users" are also announced in advance.
            (3) The Company will notify you of any changes to the Terms and Conditions. In addition, the revised Terms and Conditions will be effective on the date of notification.
            (4) By agreeing to these Terms and Conditions, you agree to visit the Service periodically to check for changes to the Terms and Conditions. In addition, the "Company" does not bear any responsibility for any losses suffered by the "User" due to lack of information about the changed "Terms".
            (5) If the "Member" does not agree to the changed "Terms and Conditions", he or she may request the "Company" to revoke (cancel) the "Membership".

            Article 4 (“Terms and Conditions” and Other Rules)

            For matters not covered in these Terms, relevant laws, business practices and "Company" notices shall apply.

            Article 5 (Application to use “Service”)

            (1) When non-members (hereinafter referred to as "applicant customers") wish to use the "Service", they must use the prescribed "Member" registration form provided by the "Member". Company" through Internet access, etc. You must fill in the required information and apply for use.
            (2) "Customers applying for use" check the box when applying for "membership" and apply for use, which is deemed to agree to these "Terms".
            (3) "Users" who fail to provide accurate information when registering as "Members" cannot claim any rights related to the use of "Services".
            (4) When children under the age of 14 apply for membership, they must obtain the consent of their legal representative (parents, etc.) and have their identity verified.
            (5) When registering as a "Member", you must provide accurate information that can be used to contact you. The "Company" may take steps to verify the information for purposes such as post management.
            (6) In principle, the "Company" will accept the use application immediately if there are no business performance or technical difficulties related to the use application stipulated in this article. However, we will not accept the following situations:

            ① When a child under 14 years old applies for use without the consent of the legal representative
            ② When companies that are unable to carry out normal business activities apply for use
            ③ When applying using other people’s information
            ④ When using the application and using an existing "e-mail" address or an "e-mail" with a mark that violates public morals in the "service" application
            ⑤ When submitting a false application to use the "service"
            ⑥ When it is confirmed that the application for use violates these Terms, is illegal or unfair
            ⑦ When there are other reasonable reasons to protect the “customer applying for use”

            (7) In principle, the "Company" will immediately accept the application for use if there are no business execution or technical difficulties when applying for use in accordance with the provisions of the preceding article.
            (8) The "Company" can resolve any reasonable reasons, including when there are obstacles in providing the "Company" "Services", or when the "Company" facilities cannot be used and inspected due to on-site reorganization, etc., the "Company" can resolve the problem. Until then, approval of use applications may be withheld.
            (9) When the application for use is approved, the "Company" may notify the "customer who applied for use" of relevant matters through "e-mail" or other methods.
            (10) The responsibility for managing the "email" and "password" rests with the "User". The "User" is responsible for any losses caused by the use of the "Service" or improper use or mismanagement by third parties. The "Company" does not assume any responsibility unless there is intentional or negligent behavior. of the company. "
            (11) In order to publish "content" and "products" on the "service", "members" must go through the "master" registration process. The details are subject to the master terms separately announced in the "service". Serve. "

            Article 6 (Start of “Service”)

            The "Service" starts immediately from the date the "Company" accepts the application for use in accordance with Article 5. However, when the "Service" cannot be started immediately due to business or technical difficulties of the "Company", the "Company" will notify the "Member".

            Article 7 ("Service" usage time)

            (1) In principle, the "Service" is provided 24 hours a day, 365 days a year, unless there is a "Company" business or technical failure or other special reasons.
            (2) When the use of the "Service" is interrupted due to force majeure such as maintenance inspection, replacement, malfunction, communication interruption or excessive use of the "Service", or when the "Company" needs it for other reasons, all or part of the service will be provided without exception , use of the "Service" may be restricted.

            Article 8 (Restrictions on the use of "Service")

            (1) If a "user" commits any of the following acts, civil or criminal legal liability may arise. The "Company" may restrict the use of the "service" and you may do so (account suspension, contract termination, etc.) or take other legal actions. In this case, the "Company" must determine the reason and date and notify the "User" or agent in advance by phone or email. However, in unavoidable circumstances, the "Company" may notify the user after restricting use. Serve".

            ① When the obligations stipulated in Article 12 are not fulfilled.
            ② When you steal someone else's "email" address and "password" or provide false membership information.
            ③ Other "Users" intentionally disrupt or impersonate the management, employees or managers of the "Company" when operating the "Service" or using the "Service".
            ④ Disseminate information that is inconsistent with the facts, depict the "Company" and "Services", thereby interfering with the business activities of the "Company", "Services" or "Contents" or damaging the reputation of the "Company", "Services" or "Contents".
            ⑤ When engaging in destructive behavior, publishing or disseminating information such as symbols, words, sounds, sounds, images and other information related to the use of obscene, violent language and other behaviors that undermine public order or social morality.
            ⑥ When the "User" is determined to be using the "Service" for harming the national or social public interests or for criminal purposes.
            ⑦ When you act to damage the reputation of others or cause harm to others.
            ⑧ Purchase and refund policy of "Service", etc.
            ⑨ The "Company" or other "users" and other third parties intentionally misuse the "Service" to cause damage to the "Service" or hinder the normal use of the "Service" such as illegally copying or cracking the "Content" or publishing virus-infected materials.
            ⑩ When it is determined that someone’s intellectual property rights have been infringed, or the intellectual property right holder can assert his or her intellectual property rights.
            ⑩ When violating relevant laws and regulations and other matters stipulated in these Terms.
            ⑪ When it is objectively recognized that the content promotes or is related to crime.
            ⑫ The content describes or recommends the use of harmful substances such as drugs or narcotics, or provides instructions on how to use them.

            (2) If the "User" corrects the behavior related to the preceding paragraph regardless of the "Company"'s request, the "Company" may take measures such as restricting the use of the "Service" without the "User's" consent.
            In addition, the "User" cannot request an extension of the "Service" period or a refund of the "Content" or "Product" due to these measures. If there is any objection to the "Company"'s measures, the "Company" must notify the "Company". I can explain this.

            Article 9 (Suspension of the provision of "services")

            (1) Under any of the following circumstances, the "Company" may limit or suspend all or part of the functions of the "Service".

            ① When due to unavoidable reasons such as maintenance, inspection, replacement, malfunction, etc. of computers and other information and communication equipment used by the "Company"
            ② Telecommunications services provided by telecommunications operators or overseas services currently used by the "Company" in accordance with the provisions of the Telecommunications Business Act
            ③ When war, accidents, natural disasters or similar national emergencies occur or are likely to occur, the normal use of the "Service" is disrupted due to power outages, failure of the "Service" facilities, or excessive use of the "Service" if there is any

            (2) The "content" or functions of the "services" provided for free by the "Company" may be restricted, suspended or converted into paid services "Company" in whole or in part due to the management policies of the "Company" and other reasons.

            Article 10 (Provision of information and publication of advertisements)

            (1) The "Company" publishes various information and advertisements that the "User" deems necessary when using the "Service" through slogans, "e-mails", mobile phone text messages, etc. SMS). , can be provided to "users" by phone, email, etc. If the "User" does not wish to receive it, he may refuse to receive it in the manner provided by the "Company".
            (2) Even if the "user" chooses not to accept the service according to the proviso of the preceding paragraph, the "user" must pay attention to matters such as changes in the "Terms and Conditions", personal information processing policy, and other important matters that may affect the interests of the "user" matter. Information may be provided by "e-mail" or other means.

            Article 11 (Obligations of the “Company”)

            (1) The "Company" must faithfully exercise its rights and obligations stipulated in the law and these Terms.
            (2) The "Company" must try its best to provide the "Service" continuously and stably by always maintaining/repairing the "Service" providing facilities in an operational condition.
            (3) The "Company" will not disclose or disseminate the "Member's" personal information obtained through the "Service" to third parties without the Member's consent. However, according to the personal information processing policy of the "Service", there are exceptions to the provision of personal information to third parties, or in accordance with the provisions of the "Personal Information Protection Act" and other relevant laws, the relevant state agency makes a written request. Telecommunications Framework Act, and for investigative purposes. This provision does not apply if a search warrant is produced.
            (4) The "Company" collects personal information and information guidance related to the use of the "Service" with the consent of the "User" in order to conduct customer marketing such as new "content" or "products", event information, etc. Survey, you can take advantage of it. However, if the use exceeds the scope of the "User"'s consent, the "User"'s consent must be obtained, in which case the "User" may reject the "Company's" consent request.
            (5) If the "Company" believes that the opinions or complaints raised by the "Members" regarding the use of the "Service" are reasonable, it will try its best to resolve them. For opinions or complaints raised by "members", the processing process and results will be communicated through "e-mail" and other methods.

            Article 12 (Obligations of "User")

            (1) "User" must provide "Member" registration information or "When entering or changing new "Master" registration information, you must accurately fill in all your information based on facts. If you use false or other people's By registering the information, you will not be able to claim any rights and may cause problems. In this case, I bear full responsibility.
            (2) Without the prior consent of the "Company" and the "Content" provider "Master", the "User" shall not use this "Service" to engage in commercial activities. The "Company" is responsible for the results of such commercial activities. I will not lose. . In addition, if the "Company" or the "Content" copyright owner suffers damage due to such business activities, the "User" is obliged to compensate the "Company" or the "Content" copyright owner for the loss, and the "Company" or the "Content" copyright owner shall pay " Company" or the copyright owner of the "Content" shall compensate for losses. The "Content" copyright owner "User" may be restricted from using the "Service" and may claim compensation for losses through legal proceedings.
            (3) "User" may copy, photograph, record, record, capture, reproduce, edit, transmit, make available, publish, adapt, distribute, broadcast, display or sell "Content" for any purpose. You may not license or infringe copyright in the Content.
            (4) "User" may not download "Content" from the "Service" through methods not permitted by the "Company", unless the "Company" obtains express written permission to download the "Content".
            (5) "Users" must abide by relevant laws and regulations, the provisions of these Terms and the User Guide and other matters stipulated by the "Company", and shall not engage in any other behavior that is detrimental to the Company. Harm the work of the Company or harm its reputation. this is possible.
            (6) If the "User" changes the terms of the service agreement, he must immediately notify the "Company" through relevant procedures by "email" or other means.
            (7) Except for the "Company"'s liability in accordance with relevant laws and personal information processing policies, all consequences caused by negligence or fraudulent use of the "user's" "email" address and "password" shall be borne solely by the "Company" "Responsible with "User".
            (8) "User" shall not infringe the intellectual property rights of "Company" and third parties, and all consequences arising therefrom shall be borne by "User".

            Article 13 (Personal Information Protection)

            (1) The "Company" attaches great importance to protecting the personal information of "Members" and the personal information provided by "Members" to the "Company" online when using the "Company's" "Services". "Company" We are doing our best to thoroughly protect information. In addition, the protection and use of "User" personal information are subject to relevant laws and the "Company" personal information processing policy. However, the "Company" policy does not apply to other than the "Company" official website Linked Sites. Furthermore, "Company" assumes no responsibility for any information exposed attributable to "User".
            (2) The personal information provided may not be used for purposes other than the intended purpose or provided to third parties without the consent of the "user", for which the "Company" bears full responsibility. However, the following exceptions apply.

            ① When required by relevant authorities for investigation purposes in accordance with relevant laws and regulations
            ② When requested by the Information and Communications Ethics Committee
            ③ When based on other relevant laws and regulations
            ④ If it is necessary to identify a specific individual for statistical compilation, academic research or market research, etc., if it is provided in an unusable format

            (3) "Members" may request to view and correct errors in their personal information held by the "Company" at any time, and the "Company" is obliged to take necessary measures immediately.
            (4) "Members" may withdraw their consent to the collection and use of personal information at any time by terminating the service agreement, separately agree to use it for other purposes, and separately agree to provide it to third parties.
            (5) As long as the "Member" does not intend to exit and the website exists, the "Member" information held by the "Company" will continue to be retained for the "Service".
            (6) "Company" may collect "User" interests and "Service" usage information.

            Article 14 (Payment Methods for “Paid Services”)

            (1) When a "Member" wishes to use a "Paid Service", the "Company" will provide the detailed information and charging policy of the "Paid Service" on the "Paid Service" application screen, and the "Member" will process the process in accordance with Article 1 of this Policy The second stipulation is to enter the payment information required by the "Company" and apply for the "paid service".
            (2) If the "Member" applies for "Regular Payment" in the "Paid Service", the "Company" will pay the tuition for that period according to the regular payment plan selected by the "Member" without the "Member" making personal payments. I agree to automatic payments using the payment information entered by the Member on each payment date.
            (3) As far as the preceding paragraph is concerned, if the encrypted payment information registered by the "Member" is not paid due to termination of the contract, exceeding the limit, or false registration, the "Company" may try to repay it up to 3 times. The "Company" may cancel the "Regular Payment" of the "Member" if the repayment is not properly processed.

            Article 15 (Termination of Contract)

            (1) "Members" can apply to terminate the service agreement at any time in a manner determined by the "Company", and the "Company" must immediately handle it in accordance with relevant laws and other regulations. However, the "Company" may refuse to accept the application for termination of the service agreement under any of the following circumstances.

            ① When it is impossible to confirm whether the person applying to terminate the service agreement is a "member"
            ② Products purchased by the "Member" when the "Master" incurs a debt to the "Company" due to the return of the "Contents" or "Products"
            ③ When the "member" who applied to terminate the service agreement still has problems such as transaction fraud or legal disputes.

            (2) If the "Member" terminates the contract, all the "Member's" data will be destroyed immediately after termination, except where the "Company" retains the "Member's" information in accordance with relevant laws and personal information processing policies.
            (3) According to the provisions of Article 8, Paragraph 1 of these Terms, when the contract with the "Member" is terminated, the "Company" may not accept the "Member's" application to use the "Service" again.
            (4) "Members" who terminate the service agreement due to their own fault shall bear any losses related to the termination of the service agreement. The "Company" shall not bear any liability except without justifiable reasons. Attributed to the member.

            Article 16 (Disclaimer)

            (1) If the "Company" is unable to provide the "Service" due to natural disasters, wars or other force majeure, the "Company" is exempt from the responsibility to provide the "Service".
            (2) The "Company" does not assume any responsibility for the interruption of the use of the "Service" caused by the "User".
            (3) The "Company" is not liable for any damage caused by a telecommunications business operator stopping or failing to properly provide telecommunications "services".
            (4) The "Company" is not responsible for any damage caused by the "User's" computer error or the "Member's" incorrect input of personal information and "e-mail" address.
            (5) The "Company" does not bear any responsibility for the "User"'s failure to obtain or lose expected profits by using the "Services".
            (6) The "Company" does not assume any responsibility for any damage caused by the data obtained by the "User" when using the "Service". In addition, the "Company" does not bear the responsibility to compensate "Users" for the mental damage suffered by other "Users" when using the "Services".

            Article 17 (Dispute Resolution, etc.)

            (1) When a dispute occurs, the "Company" will reflect the legitimate opinions or complaints raised by the "Members" and take appropriate and prompt action. However, if it is difficult to handle it in time, the "Company" will inform the "Member" of the reason and processing schedule.
            (2) Notwithstanding the provisions of the preceding paragraph, these "Terms and Conditions", the use of the "Service" and related matters between the "Member" and the "Company" are governed by Hong Kong law.


        </pre>

          <pre v-if="current === 3" class="modal-content">

              The account will be credited within 10 minutes after the payment is successful, and relevant courses will be obtained.

              1. Refund Policy
              Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or denial of your refund.

              If you are approved, your refund will be processed and a credit will be automatically applied to your credit card or original method of payment within a certain number of days.

              2. Delayed or lost refunds
              ① If you have not received your refund, please check your bank account again first.
              ② Then contact your credit card company, it may take some time for your refund to be officially issued.
              ③ Next, contact your bank. There is usually some processing time before a refund is issued.
              ④ If you have completed all these operations and still have not received your refund, please contact us at service@momoindo.live.

              3. Return policy
              Courses and products you purchase through Momoindo are generally eligible for refunds. These products are marked "refundable." If a course or product is marked "Non-refundable," it is not eligible for a refund. Products that contain virtual currency or other consumables are marked "non-refundable" and are not eligible for refunds. Additionally, most in-app purchases are non-refundable. Momoindo is unable to provide refunds for purchases made outside of momoindo.live.

              4. When can products be refunded?
              Courses and products may be refunded within 14 days of purchase if they are marked "Refundable" or "Self-refundable." However, the run time you record must be less than 2 hours. Products that contain virtual currency or other consumables and products or games marked "Non-refundable" are not eligible for refunds.

              You will not be eligible for a refund for courses or products for which you have been banned or have violated the Terms of Service. Additionally, you may not be eligible for a refund if we determine that you are abusing the refund policy.

              5. How do I apply for a refund?
              For purchases marked "Refundable" or if you have questions about the refund process, please contact us at hello@momoindo.live.

              6. Cancellation Policy
              Cancel service
              To cancel a service, the customer must provide us with 7 days written notice of cancellation. Services will continue until we receive notification of cancellation and the cancellation is finalized and the customer is responsible for payment of the fees for said services. Please send notification to (fill in applicable content): hello@momoindo.live

              Service termination
              Terminates immediately upon expiration of the cancellation notice provided by the customer.

              Cancel in advance
              Customer may cancel services without penalty by giving written notice at least 7 days prior to the first scheduled service.

              Cancellation fee
              No cancellation fees required.

              7. Need help?
              For questions about refunds and returns, please contact us at service@momoindo.live.
        </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'

let route = useRoute()


const current = ref(1)
let dialogVisible = ref(false)
let visible = ref(false)


const toBottom = () => {
  window.scrollTo({
    top: document.querySelector('.scroll-box').scrollHeight,
    behavior: 'smooth' // 使用平滑滚动效果（可选，根据浏览器支持情况）
  })
}
const toBottomH5 = () => {
  visible.value = false
  setTimeout(() => {
    window.scrollTo({
      top: document.querySelector('.scroll-box').scrollHeight,
      behavior: 'smooth' // 使用平滑滚动效果（可选，根据浏览器支持情况）
    })
  }, 100)
}


const openModal = (flag) => {
  dialogVisible.value = flag
}


</script>


<style scoped lang="less">
.ml-35 {
  //margin-left: 35px;
}

.a-btn:hover .lang-set {
  color: #fff !important;
}

.lang-set {
  transform: translateY(4px);
  margin-right: 4px;
}

.select-item {
  width: 163px;
  height: 52px;
  padding-left: 25px;
  padding-right: 25px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.hr {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #FFF;
}


.auto-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal{
  position: relative;
  max-width: 900px;
  width: calc(100% - 40px);
  max-height: 70%;
  margin-left: -15px;
  padding-top: 30px;
  background: #FFF;
  height: 700px;
  border-radius: 20px;
}

.modal-c {
  max-height: 700px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  box-sizing: border-box;
  padding: 24px 40px 0;
  overflow-y: auto;
  white-space: pre-line;

  .modal-t {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
    //margin-top: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .modal-content {
    display: block;
    padding-bottom: 50px;
    white-space: pre-line;

  }
}


.flex-1 {
  flex: 1;
  text-align: right;
}

.close-img {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.evaluate-box.adaptation {
  .evaluate-margin {
    margin-top: 220px;
    margin-bottom: 170px;
  }

  .partners-img {
    width: 100%;
    height: auto;
    margin-bottom: 210px;
  }

  //padding-top: 210px;

  .e-item-box {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .evaluate-title {
    margin-bottom: 150px;
    color: #000;
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 59.4px */
    letter-spacing: 0.44px;
  }

  .evaluate-item {
    padding: 23px 30px 25px 34px;
    width: 373px;
    //height: 250px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
    margin-right: 20px;

    .e-item-text {
      color: #8F9195;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px; /* 156.25% */
      letter-spacing: 0.16px;
      margin-top: 16px;
      margin-bottom: 50px;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    .e-b-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        height: 50px;
        width: 50px;
        margin-right: 20px;
      }

      .e-b-name {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 30.6px */
        letter-spacing: 0.18px;
        margin-bottom: 2px;
      }

      .e-b-job {
        color: #8F9195;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px; /* 178.571% */
        letter-spacing: 0.14px;
      }
    }
  }

  .evaluate-item:last-child {
    margin-right: 0;
  }
}

.pc-visible {
  display: block;
}

.m-visible {
  max-width: 500px;
  margin: 0 auto;
  display: none;
}

@media screen and (min-width: 769px) {
  .pc-visible {
    display: block;
  }

  .m-visible {
    display: none;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    .bot-li-item, .bot-li-item a {
      list-style: none;
      color: #666;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 177.778% */
      letter-spacing: 0.18px;
      position: relative;
    }

    .bot-li-item {
      padding: 0 15px;
      cursor: pointer;

      &:last-child:after {
        position: relative;
      }

    }

    .bot-li-item:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 18px;
      background: #ccc;
      transform: translateY(-50%);
      top: 50%;
      right: -1px;
    }
  }

  .pc-pl {
    padding-left: 30px;
  }


}

@media screen and (max-width: 768px) {
  .pc-visible {
    display: none;
  }


  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .bot-li-item, .bot-li-item a {
      list-style: none;
      color: #666;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 177.778% */
      letter-spacing: 0.18px;
      position: relative;
    }

    .bot-li-item {
      cursor: pointer;
      width: 100%;
      padding: 0 15px;
    }
  }

  .m-visible {
    display: block;
  }
}


.service-box {
  //display: flex;
  //justify-content: center;
  //flex-wrap: wrap;
  .service-rate {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 24.3px */
    letter-spacing: 0.18px;
    margin-top: 24px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-left: 4px;
    }
  }

  .service-c {
    width: calc(50% - 40px);
    min-width: 420px;
    height: 720px;
    padding: 78px 45px 0;
    border-radius: 20px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 24.3px */
    letter-spacing: 0.18px;

    .service-t {
      color: #FFF;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 135%; /* 43.2px */
      letter-spacing: 0.32px;
      margin-bottom: 35px;
    }

    .service-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #4380F7;
    }
  }

  .service-title {
    color: #000;
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 59.4px */
    letter-spacing: 0.44px;
    margin-bottom: 145px;
  }

  .service-left {
    background: #1D6AFF;
    margin-right: 80px;
    position: relative;

    .good-img {
      position: absolute;
      width: 112px;
      height: 110px;
      left: 50%;
      margin-left: -56px;
      top: 0;
      margin-top: -55px;
    }
  }

  .service-right {
    background: #F2F4F6;
    color: #000000;

    .service-t {
      color: #000;
    }

    .service-item {
      border-bottom: 1px solid #E1E3E5;
    }
  }
}


.m-t-content {
  display: none;
}

.demo-1, .demo-3 {
  > div:last-child {
    display: block;
  }

  > div:first-child {
    display: none;
  }
}


//---------------


.content {
  font-family: "PingFang SC", '';
}

.copy-r {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  text-align: center;
  color: #777777;
}

.adaptation {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.content {
  width: 100%;

  background: #F7F9FC;
}

.top-pos-img {
  //position: absolute;
  //top: 72px;
  //right: -92px;
  //width: 700px;
  //height: 480px;
  //object-fit: cover;

  position: absolute;
  top: 158px;
  left: 50%;
  width: 700px;
  height: 480px;
  object-fit: cover;
}

.top-container {
  width: 100%;
  height: 920px;
  background-image: url("@/assets/image/main-bg.png");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.top-container .top-logo {
  width: auto;
  height: 24px;
}

.top-container .top-content {
  max-width: 1200px;
  height: 100%;
  padding: 0 30px;
  color: #ffffff;
  margin: 0 auto 0;
  position: relative;
  //overflow: hidden;
}

.top-container .top-text {
  margin-top: 118px;

  width: 620px;
  position: relative;
  z-index: 99;
}

.top-container .top-text > div:first-child {
  //font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 900;
  font-size: 73px;
  line-height: 86px;
}

.top-container .top-text > div:nth-child(2) {
  width: 620px;
  font-size: 20px;
  line-height: 38px;
  font-weight: 400;
  margin-top: 22px;
  margin-bottom: 97px;
}

.top-container .top-btn-item:first-child {
  margin-right: 16px;

}

.top-container .top-btn-item .demo-btn {
  background: #FFFFFF;
  color: #2D8DFF;
}

.top-container .top-btn-item .contact-btn {
  width: 215px;
}

.top-container .top-btn-item .contact-btn img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.top-container .common-btn {
  width: 170px;
  height: 64px;
  border: 2px solid #FFFFFF;
  border-radius: 56px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.top-header {
  width: 100%;
  height: 83px;
  color: #FFFFFF;

  > img:last-child {
    display: none;
  }

  > div:nth-child(2) {
    display: block;
  }
}

.top-header .demo-btn {
  width: 94px;
  height: 40px;
  border: 2px solid #FFFFFF;
  font-weight: 400;
  border-radius: 56px;
  background: none;
  margin-right: 15px;
  color: #FFFFFF;
}

.advantage-container {
  max-width: 1160px;
  //height: 338px;
  padding-bottom: 16px;
  border-radius: 60px;
  margin: 0 auto;
  transform: translateY(-179px);
  padding-top: 45px;
  //flex jc-sb ai-c
  display: flex;
  justify-content: space-between;
  //align-items: center;
  align-items: stretch;
}

.advantage-container .title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.advantage-container .advantage-item {
  width: 272px;
  //height: 338px;
  padding-bottom: 36px;
  color: #000000;
  border-radius: 40px;
  background: #fff;
}

.advantage-container .advantage-text {
  width: 230px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #8F9195;
}

.advantage-container .advantage-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 154%;
  margin-bottom: 13px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.advantage-container img {
  width: 183px;
  height: 183px;
  margin-bottom: 3px;
  margin-top: 10px;
}


.bg-img {
  width: 100%;
  height: 1210px;
  background-image: url("@/assets/image/mid-bg.png");
}

.demo-container {
  max-width: 1160px;
  padding: 0 20px;
  height: 493px;
  margin: 21px auto 0;
  //flex jc-c ai-c
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-container .demo-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 59px;
  margin-bottom: 42px;
  letter-spacing: 0.01em;
  color: #000000;
}

.demo-container .demo-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
  letter-spacing: 0.01em;
  color: #8F9195;
}

.demo-container .demo-desc.w-430 {
  width: 430px;
}

.demo-container .demo-desc.w-460 {
  width: 460px;
}


.demo-container.mt-158 {
  margin-top: 130px;
  margin-bottom: 150px;
}

.demo-container .demo-img {
  width: 607px;
  height: 560px;
  margin-right: 73px;
  object-fit: cover;
}

.demo-container .pay-img {
  width: 607px;
  height: 560px;
  margin-left: 68px;
}

.work-container {
  width: 100%;
  height: 902px;
  background-repeat: no-repeat;
  background-size: 100% 902px;
}

.work-container .work-content {
  width: 1120px;
  padding: 111px 15px 0;
  margin: 0 auto;
  color: #fff;
}

.work-container .work-content > div:first-child {
  margin-bottom: 45px;
}

.work-container .nft-img {
  margin-top: 110px;
  height: 390px;
  width: 100%;
  object-fit: cover;
}

.bg-f5 {
  background: #fff;
  //height: 1880px;
}

.platform-container {
  margin-top: 188px;
  padding-top: 160px;
}

.platform-container > div:first-child {
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 44px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.platform-container > div:nth-child(2) {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #B5C9FF;
}

.platform-container .platform-img {
  margin-top: 89px;
  width: 922px;
  height: 706px;
}

.partners-container {
  height: 1160px;
  padding-top: 175px;
}

.partners-box {
  //flex jc-fs fw-w ai-c
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.partners-container > div:first-child {
  margin-bottom: 156px;
  font-weight: 600;
  font-size: 44px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.partners-container .partner-item {
  width: 380px;
  height: 230px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.partners-container .partner-item > div:nth-child(2) {
  font-weight: 600;
  font-size: 24px;
  line-height: 170%;
  margin-top: 42px;
  margin-bottom: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.partners-container .partner-item {
  > div:nth-child(3), > div:nth-child(4) {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #8F9195;
  }
}


.partners-container .partner-img {
  width: 80px;
  height: 80px;
}

.partners-container .partner-item:nth-child(3n) {
  margin-right: 0;
}


.bg-f7f9fc {
  height: 570px;
  background: #f7f9fc;
}

.copy-right {
  padding-top: 86px;

  .bot-limit{
    margin-top: 40px;
    display: block;
    text-align: center;
    color: #666;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.18px;
  }

  .bot-email a,.bot-address{
    display: block;
    text-align: center;
    color: #666;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.18px;
    margin-top: 10px;
  }
}

.copy-right > div:first-child {
  font-weight: 600;
  font-size: 44px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 20px;
}



.about-container {
  width: 720px;
  height: 541px;
  background: #FFFFFF;
  border-radius: 40px;
  overflow: hidden;
}

.about-container img {
  width: 720px;
  height: 277px;
  margin-bottom: 60px;
}


.close-btn {
  width: 42px !important;
  height: 42px !important;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  right: 24px;
}

.feature-title {
  color: #000;
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 59.4px */
  letter-spacing: 0.44px;
  //margin-top: 220px;
  margin-bottom: 141px;
}

.run-box.access-box {
  margin-top: 30px;

  > div:first-child {
    margin-bottom: 116px;
  }

  .access-item img {
    width: 226px;
    height: 300px;
  }
}

.access-box {
  > div:first-child {
    font-weight: 600;
    height: 59px;
    text-align: center;
    margin-bottom: 136px;

  }

  > div:nth-child(2) {
    //flex jc-c ai-c
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .arrow-box {
    > img:first-child {
      display: none;
    }

    > img:last-child {
      width: 12px;
      height: 20px;
      display: block;
    }
  }

  .access-item {
    width: 320px;
    height: 298px;
    border-radius: 20px;
    background: #f7f9fc;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  //.access-item:first-child img, .access-item:last-child img {
  //  width: 232px;
  //  height: 187px;
  //}
  //
  //.access-item:nth-child(3) img {
  //  width: 213px;
  //  height: 169px;
  //}
}

.a-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #BFD1FF;
  cursor: pointer;
  margin-right: 38px;
}

.a-btn:hover {
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .top-content {
    overflow: hidden;
  }

  .run-box.access-box {
    margin-top: -90px;

    > div:first-child {
      margin-bottom: 30px;
    }

    .access-item img {
      width: 226px;
      height: 300px;
    }
  }

  .feature-title {
    margin-top: 120px;
    margin-bottom: 180px;
  }
}

@media screen and (max-width: 980px) {
  .pc-auto {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .service-left {
    margin-right: 0 !important;
    margin-bottom: 60px;
  }

  .evaluate-box.adaptation {
    padding-top: 180px;

    .evaluate-title {
      margin-bottom: 150px;
    }

    .e-item-box {
      flex-wrap: wrap;

      .evaluate-item {
        margin-bottom: 40px;
      }
    }

    .e-item-box .evaluate-item:nth-child(2) {
      margin-right: 0;
    }
  }

  .adaptation .evaluate-margin {
    margin-top: 160px !important;
    margin-bottom: 130px !important;
  }

  .evaluate-box.adaptation .partners-img {
    margin-bottom: 130px;
  }

}

.modal-t-2{
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  letter-spacing: 0.18px;
  margin-top: 5px;
}

@import "./mobile";
</style>
